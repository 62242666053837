<template>
  <div id="getCouponList">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="couponName" label="优惠券名称" align="center">
        </el-table-column>
        <el-table-column prop="couponType" label="优惠券类型" align="center">
        </el-table-column>
        <el-table-column prop="plateNum" label="车牌号" align="center">
        </el-table-column>
        <el-table-column prop="useFlag" label="使用状态" align="center">
        </el-table-column>
        <el-table-column label="用户头像" align="center">
          <template slot-scope="scope">
            <el-image
              class="platformAvatar"
              v-if="scope.row.platformAvatarUrl"
              :src="scope.row.platformAvatarUrl"
              :preview-src-list="[scope.row.platformAvatarUrl]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="platformUserName"
          label="用户昵称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="bindPhone" label="用户手机号" align="center">
        </el-table-column>
        <el-table-column prop="issueTime" label="领券时间" align="center">
        </el-table-column>
        <el-table-column prop="expireTime" label="过期时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filtrate: {
        keyword: "",
      },

      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    reset() {
      this.filtrate = {
        keyword: "",
      };
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/coupon/list/issued", {
          merchantId: this.$route.params.id,
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.expireTime = this.$moment(item.expireTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.issueTime = this.$moment(item.issueTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#getCouponList {
  .table-container {
    .platformAvatar {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
